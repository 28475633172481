.upload-photo {
  text-align: center;
  border: 1px dashed rgb(156, 156, 156);
  width: 120px;
  height: 100px;
  cursor: pointer;
  border-radius: 15px;
  color: rgb(204, 204, 204);

  .loading-container {
    padding-top: 25px;

    // .loading {
    //   font-size: 40 !important;
    //   color: #009b00 !important;
    // }
  }

  .container-no-pict {
    position: absolute;
    top: 8%;
    width: 100%;
  }

  .pict {
    width: 100%;
    height: 80px;
    margin-top: 10px;
  }
}
