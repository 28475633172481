@import "../../../style/color-variable.scss";

.login-container {
  height: 100vh;

  .company-logo {
    width: 4.3rem;
  }

  .form-container {
    margin-top: 1.875rem;

    .login-button {
      background-color: $green !important;
      border: 1px solid $green !important;
      line-height: 100% !important;
    }

    .login-button:hover {
      background-color: #45a545 !important;
      font-weight: bold !important;
    }

    .ant-form-item-explain {
      padding-bottom: 10px !important;
    }
  }

  .footer-container {
    margin-top: 9.375rem;

    .footer-paragraph {
      text-align: center;
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
