.category-box {
  border: 1px solid #dedede;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  color: rgb(146, 146, 146);

  &:hover {
    box-shadow: 0px 3px 10px 1px #dedede;
    color: black;

    .edit-icon {
      color: #009b00;
    }

    .delete-icon {
      color: red;
    }
  }

  .action-section {
    text-align: right;

    .edit-icon {
      cursor: pointer;
    }

    .delete-icon {
      cursor: pointer;
    }

    .save-icon {
      cursor: pointer;
      font-size: 18px;
      margin-top: 5px;
      color: rgb(58, 58, 58);

      &:hover {
        color: #009b00;
      }
    }

    .cancel-icon {
      cursor: pointer;
      font-size: 18px;
      color: rgb(58, 58, 58);

      &:hover {
        color: red;
      }
    }
  }
}
