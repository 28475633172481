.tag-container {
  box-shadow: 0px 3px 8px #dfdfdf;
  padding: 5px 7px;
  border-radius: 5px;

  .tag-action-container {
    display: none;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    width: 85%;
    text-align: center;
    opacity: 0.8;

    .icon-action-tag {
      cursor: pointer;

      &:hover {
        color: rgb(11, 136, 11);
      }
    }
  }

  &:hover {
    .tag-action-container {
      display: block;
      position: absolute;
    }
  }

  .ant-input-borderless {
    padding: 0px !important;
  }
}
