.chart-title {
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 3px;
}

.chartjs-render-monitor {
  height: 100% !important;
  width: 100% !important;
}
