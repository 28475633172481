.btn-create-category {
  border: 2px solid #009b00 !important;
  background-color: #009b00 !important;
  width: 100%;
  color: white !important;
  border-radius: 7px !important;
  margin-bottom: 12px;
  height: 40px !important;
}

.form-input-category {
  border-radius: 5px !important;

  &:hover {
    border: 1px solid #009b00 !important;
  }
}

// .ant-input {
//   border-radius: 5px !important;

//   &:hover {
//     border: 1px solid #009b00 !important;
//   }
// }
