.button-modal-set-schedule {
  background-color: #009b00 !important;
  border: 2px solid #009b00 !important;
  color: white !important;
  border-radius: 10px !important;
  // width: 70px;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.ant-modal.schedule-modal {
  width: 1100px !important;

  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .ant-modal-header {
    border: 0px !important;
    border-radius: 0.9375rem 0.9375rem 0px 0px !important;
  }

  .ant-modal-body {
    padding-top: 0.625rem !important;
  }

  .ant-modal-footer {
    text-align: center !important;
    border: 0px !important;
    padding: 0px !important;
  }

  .ant-picker-range {
    &:hover {
      border: 1px solid #009b00 !important;
    }
  }

  .ant-picker-focused {
    &:hover {
      border: 1px solid #009b00 !important;
    }
  }

  .ant-input {
    border-radius: 5px !important;

    &:hover {
      border: 1px solid #009b00 !important;
    }
  }

  .table-head-section {
    padding-bottom: 4px;

    .table-head-container {
      padding: 10px 10px;
      border: 2px solid black;
      border-radius: 5px;

      .head-col-day {
        padding-left: 25px;
      }

      .head-col-hours {
        padding-left: 5px;
      }

      .head-col-deposit {
      }

      .head-col-price {
        padding-left: 26px;
      }

      .head-col-discount {
        padding-left: 8px;
      }
    }
  }

  .table-body-section {
    padding-bottom: 4px;

    .table-body-container {
      padding: 5px 10px;
      border: 2px solid rgb(146, 146, 146);
      border-radius: 5px;
      color: rgb(153, 153, 153);

      .ant-form-item {
        margin-bottom: 0px !important;
      }

      .body-col-hourpicker {
        padding-left: 5px;
      }

      .body-col-depositform {
        padding-left: 14px;
      }

      .body-col-priceform {
        padding-left: 15px;
      }

      .body-col-discount {
        padding-left: 15px;

        .col-discount-form-section {
          padding-right: 5px;
        }

        .col-unit-form-section {
          .ant-select-selector {
            border-radius: 5px !important;
          }
        }

        .col-action-section {
          padding-left: 5px;
          //   font-size: 20px;
          //   padding-top: 2px;

          .add-icon {
            font-size: 20px;
            cursor: pointer;
            color: rgb(114, 114, 114);

            &:hover {
              color: #009b00;
            }
          }
        }
      }
    }
  }
}

.button-cancel-schedule {
  background-color: white !important;
  border: 1px solid red !important;
  color: red !important;
  border-radius: 15px !important;
  width: 100px;

  &:hover {
    border: 2px solid red !important;
    background-color: red !important;
    color: white !important;
  }
}

.button-save-schedule {
  background-color: #009b00 !important;
  border: 2px solid #009b00 !important;
  color: white !important;
  border-radius: 15px !important;
  width: 100px;
  margin-right: 10px;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.checkbox-day {
  .ant-checkbox-inner {
    background-color: rgb(16, 155, 16) !important;
    border: 1px solid rgb(16, 155, 16) !important;
  }
}
