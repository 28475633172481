@import "./color-variable.scss";

// STANDARD SPACE BETWEEN TWO FORM
.row-form {
  padding-bottom: 10px;
}

// FOR CUSTOM ANTD INPUT WITHOUT ICON INSIDE INPUT FORM
.form-input {
  border-radius: 7px !important;

  &:hover {
    border: 1px solid $green !important;
  }

  &:focus {
    border: 1px solid $green !important;
    box-shadow: none !important;
  }
}
