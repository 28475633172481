@page {
  size: landscape;
  margin-bottom: 5mm;
  margin-top: 5mm;
  margin-left: 5mm;
  margin-right: 5mm;
}

.invoice {
  display: none;
}

@media print {
  .invoice {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    display: flex;
    font-family: "Open Sans", sans-serif;
    page-break-after: always;
  }

  .text-red {
    color: red;
  }

  .text-xs {
    font-size: 11px;
    font-weight: 400;
  }

  .text-sm {
    font-size: 11px;
    font-weight: 700;
  }

  .ant-layout {
    display: none !important;
  }

  .title-inv {
    font-size: 20px;
    font-weight: 800;
  }

  .ant-dropdown-open {
    display: none !important;
  }

  #button-id {
    display: none !important;
  }

  .box {
    width: 11px;
    height: 11px;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
  }

  .tbl-items {
    width: 100%;
  }

  .w-50\% {
    width: 50%;
  }

  .w-30\% {
    width: 30%;
  }
  .w-40\% {
    width: 40%;
  }

  th {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .mb-29 {
    margin-bottom: 29px;
  }

  .py-6,
  td {
    padding: 6px 6px 0px 0px;
    vertical-align: top;
  }

  .pb-12 {
    padding-bottom: 12px;
  }

  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .item-center {
    align-items: center;
  }

  .flex-end {
    align-items: flex-end;
  }

  .footer-item-inv {
    width: 50%;
  }
  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px #bdbdbd dashed;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-md {
    font-size: 12px;
  }

  .font-w700 {
    font-weight: 700;
  }
  .font-w500 {
    font-weight: 500;
  }

  .font-w600 {
    font-weight: 600;
  }

  .text-lg {
    font-size: 16px;
  }

  .bg-subtotal {
    background-color: #dce1dc;
    padding: 4px 10px 4px 10px;
    margin-top: 10px;
    border-radius: 5px;
  }

  .bg-total {
    background-color: #ebf5ec;
    border-radius: 5px;
    padding: 8px 10px 8px 10px;
    margin-top: 20px;
  }

  .footer-inv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .border-right {
    border-right: 1px black dashed;
  }
}
