.venue-photo {
  width: 160px;
  height: 90px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 1px #adadad;
  margin-top: 10px;
}

.custom-table-head-section {
  padding-bottom: 4px;

  .custom-table-head-container {
    padding: 8px 10px;
    border: 0.5px solid #BDBDBD;
    border-radius: 10px;
    background-color: #EDF8F9;
    font-weight: bold
  }
}

.custom-table-body-section {
  padding-bottom: 4px;

  .custom-table-body-container {
    padding: 3px 10px;
    border: 0.5px solid #BDBDBD !important;
    border-radius: 10px;
    color: #333333;

    &:hover {
      background-color: #EDF8F9;
    }
  }
}
