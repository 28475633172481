.upload-photo {
  text-align: center;
  border: 1px dashed rgb(156, 156, 156);
  width: 120px;
  height: 100px;
  cursor: pointer;
  border-radius: 15px;
  color: rgb(204, 204, 204);
}

.left-section {
  padding-bottom: 20px;
}

.row-edit-hobby {
  padding-bottom: 10px;

  .ant-checkbox-inner {
    background-color: rgb(16, 155, 16) !important;
    border: 1px solid rgb(16, 155, 16) !important;
  }
}

.right-section {
  border-left: 1px dashed rgb(211, 211, 211);
  padding-left: 20px;
}

.button-save-edit {
  background-color: #009b00 !important;
  border: 1px solid #009b00 !important;
  color: white !important;
  border-radius: 8px !important;
  margin-right: 5px !important;
  list-style: 100%;

  &:hover {
    border: 1px solid #009b00 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.form-input {
  border-radius: 7px !important;

  &:hover {
    border: 1px solid #009b00 !important;
  }
}

.ant-picker.ant-picker-range {
  border-radius: 8px !important;
}

.cancel-button-operationhours {
  border: 1px solid rgb(255, 49, 49) !important;
  color: rgb(255, 49, 49) !important;
  border-radius: 15px !important;
  margin-right: 5px !important;

  &:hover {
    background-color: red !important;
    color: white !important;
  }
}

.save-button-operationhours {
  background-color: #009b00 !important;
  color: white !important;
  border: 2px solid #009b00 !important;
  border-radius: 15px !important;
  color: rgb(179, 179, 179);
}

// OperationalHourHobby

input.form-input::-webkit-outer-spin-button,
input.form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.form-input[type="number"] {
  -moz-appearance: textfield;
}
