.custom-modal-detailphoto {
  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .ant-modal-body {
    padding: 0px !important;
  }

  .img-doc-detail {
    // width: 100%;
    width: 25rem;
    border-radius: 0.9375rem;
    height: 250px;
  }

  .ant-modal-footer {
    display: none !important;
  }
}
