
.radio-custom-transaction {
	.ant-radio-checked .ant-radio-inner{
	  border-color: #D81712 !important ;
	}

	.ant-radio-checked .ant-radio-inner:after{
	  background-color: #D81712;
	}

	.ant-radio:hover .ant-radio-inner {
	  border-color: #D81712 ;
	}
}
