.item-detail-container {
  padding: 3px 0px;

  .label-container {
    padding: 3px 0px;
    color: rgb(0, 0, 0);
    font-size: 15px;
  }

  .value-container {
    border: 0.5px solid #BDBDBD;
    padding: 3px 10px;
    border-radius: 10px;
    color: #535353;
  }
}
