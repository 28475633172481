.ant-modal {
  width: 25rem !important;
}

.custom-modal {
  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .ant-modal-header {
    border: 0px !important;
    border-radius: 0.9375rem 0.9375rem 0px 0px !important;
  }

  .ant-modal-body {
    padding-top: 0.625rem !important;
  }

  .ant-modal-footer {
    text-align: center !important;
    border: 0px !important;
    padding: 0px !important;
  }

  .cancel-button {
    border: 1px solid red !important;
    border-radius: 0.9375rem !important;
    background-color: red !important;
    width: 5rem !important;
    color: white !important;
    margin-right: 0.5rem !important;
  }

  .ok-button {
    border: 1px solid green !important;
    border-radius: 0.9375rem !important;
    background-color: green !important;
    width: 5rem !important;
  }
}
