.btn-download-template {
  width: 100%;
  height: 40px !important;
  line-height: 100% !important;
  background-color: #009b00 !important;
  color: white !important;
  border: 2px solid #009b00 !important;
  border-radius: 7px !important;
}

.upload-box {
  border: 1px dotted gray;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 7px;
  padding: 7px 5px;

  .upload-text {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }

  .information-text {
    text-align: center;
    width: 100%;
    color: rgb(165, 165, 165);
    margin-top: 3px;
  }
}

.upload-bulk {
  .ant-upload {
    width: 100% !important;
  }
}
