.user-account-container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;

  .user-container {
    padding: 0.4rem 0rem 0.4rem 0rem;
    display: flex;

    // .photo-container {
    //   width: 2.8rem;
    //   height: 2.8rem;
    //   border: 2px solid white;
    //   background-color: rgb(226, 226, 226);
    //   border-radius: 10px;
    //   color: rgb(155, 155, 155);
    //   padding: 0.2rem 0.3rem 0rem 0.4rem;
    // }

    .account-section {
      width: 100%;

      .user-name {
        font-weight: bold;
        color: black;
      }

      .user-role {
        font-size: 12px;
        color: green;
        font-weight: bold;
      }
    }
  }

  .menu-section {
    padding: 1px 0px;
  }
}
