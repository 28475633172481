.merchant-photo {
  width: 160px;
  height: 90px;
  border-radius: 10px;
  margin-top: 10px;
  //   box-shadow: 0px 5px 10px 1px #adadad;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #009b00 !important;
    border: 1px solid #009b00 !important;
  }
}

.tag {
  padding: 4px 7px;
  border-radius: 5px;
  background-color: rgb(197, 248, 197);
}

.custom-table-head-section {
  padding-bottom: 4px;

  .custom-table-head-container {
    padding: 8px 10px;
    border: 0.5px solid #BDBDBD;
    border-radius: 10px;
    background-color: #EDF8F9;
    font-weight: bold
  }
}

.custom-table-body-section {
  padding-bottom: 4px;

  .custom-table-body-container {
    padding: 3px 10px;
    border: 0.5px solid #BDBDBD !important;
    border-radius: 10px;
    color: #333333;

    &:hover {
      background-color: #EDF8F9;
    }
  }
}
