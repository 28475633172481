.sidemenu-container {
  height: calc(90% - 50px);
  overflow: auto;
  overflow-x: hidden;
}

.ant-menu-item {
  &:hover {
    color: #009b00 !important;
  }
}

.ant-menu-item-selected {
  background-color: #c9f7c9 !important;
  color: #009b00 !important;
  // border-radius: 20px !important;

  .menu-link {
    color: #009b00 !important;
  }
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-submenu-title:hover {
  color: #009b00 !important;

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background: #009b00 !important;
  }
}

.ant-menu-submenu-selected {
  color: #009b00 !important;
}

.menu-link:hover {
  color: #009b00 !important;
}

.menu-link::before {
  color: #c9f7c9 !important;
}
