@import "../../../style/color-variable.scss";

.ant-modal.modal-notifcation {
  width: 350px !important;

  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .ant-modal-header {
    border: 0px !important;
    border-radius: 0.9375rem 0.9375rem 0px 0px !important;
  }

  .ant-modal-body {
    padding-top: 10px !important;
  }

  .ant-modal-footer {
    text-align: center !important;
    border: 0px !important;
    padding: 0px !important;
  }
}

.container-body-notif {
  height: 360px;
  overflow: auto;
  padding: 5px 10px;
  -ms-overflow-style: none;
}

.container-body-notif::-webkit-scrollbar {
  display: none;
}

.item-box-notif {
  box-shadow: 0px 3px 10px #dfdfdf;
  min-height: 60px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: rgb(238, 255, 238);
  }

  .order-number-notif {
    font-weight: bold;
  }

  .order-date-notif {
    color: rgb(153, 153, 153);
    font-size: 13px;
  }

  .see-detail-notif {
    margin-top: 15px;
    cursor: pointer;
    color: $green;

    &:hover {
      color: green;
      font-size: 15px;
    }
  }
}
