.sidebar {
  padding: 0.9375rem;
  padding-right: 0px;
  min-height: 100vh;
  position: relative;

  .sidebar-container {
    height: 100% !important;
    border-radius: 0.9375rem !important;

    .brand-logo-container {
      width: 100%;
      padding: 1.0625rem;
      text-align: center;

      .brand-logo-big {
        width: 3.125rem;
      }

      .brand-logo-small {
        width: 2rem;
      }
    }

    .collapse-menu {
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0.625rem;
      font-size: 1.3rem;

      .collapse-icon {
        cursor: pointer;

        &:hover {
          color: #009b00;
        }
      }
    }
  }
}
