.back-button-modal-bank {
  background-color: red !important;
  color: white !important;
  border: 2px solid red !important;
  border-radius: 15px !important;
}

.save-button-modal-bank {
  background-color: #009b00 !important;
  color: white !important;
  border: 2px solid #009b00 !important;
  border-radius: 15px !important;
  color: rgb(179, 179, 179);
}
