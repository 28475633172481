.report-box-container {
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px 1px #dedede;
  color: rgb(73, 73, 73);
  height: 100%;

  .report-box-icon {
    text-align: center;
    font-size: 40px;
    color: #028f02;
  }

  .report-box-name {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: rgb(0, 0, 0);
  }

  .report-value-section {
    padding: 5px 0px;
    text-align: center;
    color: rgb(163, 160, 160);
  }
}
