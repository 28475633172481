$green-moaja: #009b00;

.feature-button {
  border: 2px solid $green-moaja !important;
  border-radius: 20px !important;
  background-color: $green-moaja !important;
  color: white !important;
  width: 110px !important;
  height: 35px !important;
  // padding: 3px !important;
  // font-size: 1em !important;
  margin-right: 0.3125rem;
  // vertical-align: middle;
  line-height: 100% !important;
  align-items: center;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}
