.button-modal-edit-hobby {
  background-color: #009b00 !important;
  border: 2px solid #009b00 !important;
  color: white !important;
  border-radius: 10px !important;
  // width: 70px;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.table-head-operation-hour {
  padding-bottom: 8px;

  .table-head-container {
    // border: 2px solid black;
    padding: 10px 5px;
    border-radius: 10px;
    // background-color: rgb(221, 255, 221);
    box-shadow: 0px 3px 8px #dfdfdf;
  }
}

.table-body-data {
  //   border: 2px solid rgb(194, 194, 194);
  border-radius: 10px;
  padding: 8px 5px;
  margin-bottom: 8px;
  //   background-color: rgb(234, 255, 234);
  box-shadow: 0px 3px 8px #dfdfdf;
}
