.header-container {
  // border: 1px solid black !important;
  // height: 5.0625rem !important;
  height: 90px;
  padding: 0.9375rem 0.9375rem;

  .page-title-container {
    background-color: white;
    height: 100%;
    border-radius: 0.9375rem;
    padding: 0px 1.25rem;
  }

  .user-info-container {
    //   border: 1px solid black !important;
    height: 100%;
    padding-left: 0.9375rem;

    .user-info {
      background-color: white;
      height: 100%;
      width: 100%;
      border-radius: 0.9375rem;
    }
  }
}
