.pagelist-container {
  height: 100%;
  // overflow: auto;

  .pagelist-top-container {
    height: 65px;

    .left-top {
      margin: 10px 0px;
    }

    .right-top {
      margin: 10px 0px;
    }
  }

  .pagelist-content-container {
    height: calc(75vh - 60px);
    overflow: auto;
  }

  .pagelist-content-container::-webkit-scrollbar {
    display: none !important;
  }

  .pagelist-footer-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 1rem);
    height: 60px;
    padding-right: 20px;
  }
}
