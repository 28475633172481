.detail-title-container {
  padding: 20px 0px;

  .detail-section-title {
    font-size: 22px;
    font-weight: bold;
  }

  .detail-section-line {
    width: 100%;
    height: 1px;
    background: #BDBDBD;
  }
}
