@import "../../../../../style/color-variable.scss";

.ant-modal.modal-refund {
  width: 700px !important;

  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .ant-modal-header {
    border: 0px !important;
    border-radius: 0.9375rem 0.9375rem 0px 0px !important;
  }

  .ant-modal-footer {
    text-align: center !important;
    border: 0px !important;
    padding: 0px !important;
  }
}

.refund-quantity-form {
  border-radius: 7px !important;

  &:hover {
    border: 1px solid $green !important;
  }

  &:focus {
    border: 1px solid $green !important;
    box-shadow: none !important;
  }
}

.add-refund-btn {
  border: 1px solid #009b00 !important;
  border-radius: 8px !important;
  background-color: #009b00 !important;
  color: white !important;
  font-size: 14px !important;
  margin-right: 0.3125rem;
  line-height: 100% !important;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.delete-refund-btn {
  border: 1px solid rgb(253, 0, 0) !important;
  border-radius: 8px !important;
  background-color: white !important;
  color: rgb(253, 0, 0) !important;
  font-size: 14px !important;
  margin-right: 0.3125rem;
  line-height: 100% !important;

  &:hover {
    border: 2px solid rgb(221, 0, 0) !important;
    background-color: rgb(221, 0, 0) !important;
    color: white !important;
  }
}
