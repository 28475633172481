.modal-confirmation {
  .ant-modal-close-x {
    display: none !important;
  }

  .ant-modal-content {
    border-radius: 0.9375rem !important;
  }

  .warning-logo {
    font-size: 80px;
    color: rgb(253, 51, 51);
  }

  .confirmation-title {
    font-size: 20px;
  }

  .confirmation-paragraph {
    padding-top: 8px;
  }

  .ant-modal-footer {
    border: 0px !important;
    padding: 0px !important;
  }

  .action-section {
    padding-top: 20px;
    text-align: center;

    .button-cancel {
      background-color: green;
      border: 0px !important;
      color: white;
      width: 80px;
      border-radius: 0.9375rem;
      margin-right: 5px;
    }

    .button-okey {
      background-color: red;
      border: 0px !important;
      color: white;
      width: 80px;
      border-radius: 0.9375rem;
    }
  }
}
