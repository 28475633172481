.ok-button {
  border: 1px solid #009b00 !important;
  border-radius: 20px !important;
  background-color: #009b00 !important;
  color: white !important;
  font-size: 14px !important;
  margin-right: 0.3125rem;
  line-height: 100% !important;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}
