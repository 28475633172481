.modal-processsechedule {
	.ant-modal-content {
		min-height: 300px;
		.ant-modal-body {
			min-height: 300px;
		    display: flex;
		    flex-direction: column;
		    justify-content: space-between;
		}
	}
}

.col-time-hour {
	width: -webkit-fill-available;
}