@import "./color-variable.scss";

// STYLE FORM INPUT WITH ICON INSIDE THE INPUT
.ant-input-affix-wrapper {
  border-radius: 3.125rem !important;
  line-height: normal !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: 1px solid $green !important;
}

.ant-input-affix-wrapper:hover {
  border: 1px solid $green !important;
}

// CUSTOM ANTD SELECT
.ant-select-selector {
  border-radius: 7px !important;

  &:hover {
    border: 1px solid $green !important;
  }

  .ant-select-selection {
    background-color: $green !important;
  }
}

.ant-select-focused {
  .ant-select-selector {
    border: 1px solid $green !important;
    box-shadow: none !important;
  }
}

.ant-select-item-option-selected {
  background-color: $light-green !important;
}

// CUSTOM FORM
.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-item-label {
  padding-bottom: 5px !important;
}

// CUSTOM CHECKBOX
.ant-checkbox {
  .ant-checkbox-input {
    &:hover {
      border: 1px solid $green !important;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $green !important;
    border: 1px solid $green !important;
  }
}

// CUSTOM DATE RANGE PICKER
.ant-picker-range {
  &:hover {
    border: 1px solid $green !important;
  }
}

.ant-picker-focused {
  box-shadow: none !important;
  border: 1px solid $green !important;
}

.ant-picker-active-bar {
  border: 1px solid $green !important;
}

.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background-color: $light-green !important;
  }
}

.ant-btn-primary {
  background-color: $green !important;
  border: 1px solid $green !important;
}
